import React from 'react';
import { graphql } from 'gatsby';
import LayoutDefault from '../../layouts/LayoutDefault';
import PageMetaData from '../../components/layout/PageMetaData';
import { SectionTilesTypesProps } from '../../utils/SectionProps';
import { AllJsonData } from '../../types/graphql';
import { Service } from '../../types/service';
import Services from '../../components/sections/services/Services';

interface ServicesProps extends SectionTilesTypesProps, AllJsonData<Service, { services: [] }> {}

const ServicesPage: React.FC<ServicesProps> = ({ data }) => {
  const services = data?.services.nodes || [];

  return (
    <LayoutDefault>
      <PageMetaData title="Services" />
      <Services
        services={services}
        title="Services"
        subtitle="Unlock your team's full potential with Atlassian Tools."
        description="Our team specializes in services and solutions designed to maximize the benefits of Atlassian tools. By seamlessly integrating processes and technology, we provide teams with versatile options to enhance their workflows, project management, Agile adoption, operational functions, and customer support processes, irrespective of industry."
        bottomDescription="Interested in any of the above services? Contact us right away! We're here to assist you every step of the way."
      />
    </LayoutDefault>
  );
};

export default ServicesPage;

export const query = graphql`
  query ServicesPage {
    services: allJson(filter: { contentName: { eq: "services" } }, sort: { fields: order }) {
      nodes {
        id
        title
        description
        image {
          publicURL
        }
      }
    }
  }
`;
